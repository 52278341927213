<script>
export default {
  name: "BaseControl",
  props: {
    controls: {
      type: Array,
      required: true
    }
  }
};
</script>
<style>
.property-wrapper {
  padding: 0.2rem;
  position: relative;
}
</style>
